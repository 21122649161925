import React from 'react'
import { withRouter } from 'react-router-dom'
import {InputNumber,Form, Descriptions, Card, Divider, Table, Button, message, Modal, Input, Radio,Popconfirm ,Upload ,Icon ,Tooltip ,DatePicker, } from 'antd';
import { getBlindboxTicketList, addBlindboxTicket,   putBlindboxTicket ,deleteBlindboxTicket ,  getGoodsList,  getCouponList, getGoodsPieceList ,  getBlindboxDetail,getOrderDetail,putOrder, confirmOrderOrder , StripeRefundOrder , putOrderStatus} from "../../action/goodAction"

import { GetPartnerStatementDetail ,  createStatement,   putPartnerStatementSettle} from "../../action/authAction";

import intl from 'react-intl-universal';
import moment from 'moment';
const { TextArea } = Input;

let currency_symbol = "$"; 

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}
function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}




class OrderDetail extends React.Component {
    //constructor(props) {
    //    super(props); //this.state = {}
        state = { 
            data: "", 
            is_show: false, 
            order_gst: 0 ,

            order_gst_inc : 0,//含gst 
            order_gst_excl:0,//不含gst 
            order_gst_total:0,//总计gst
            weight_total:0,

            deliveryVisible:false,//发货管理
            refundvisible:false,
            refundvisibleGoods:false,
            refund_goods_price:0,//部分商品退款的价格
            ItemData: "",
            partner_message:"",
            imageUrl: "",

            delivery_company_name:"",
            delivery_partner_message:"",
            delivery_partner_note:"",
            delivery_no:"",
            delivery_image:"",
            delivery_track:"",
            delivery_at:"",
            refund_message:"",
            refund_message_part:"",
            refund_spu_id:null,
            refund_spu_num:1,

            statementvisible:false,//更改清单状态
            feeVisible:false, 

            total_system_order_price:"",//【订单收入】系统收款：平台给商家的订单费用（包含订单服务费+用户手续费）合计
            total_system_order_charge_money:"",//【服务费支出】系统收款：商家给平台的订单服务费合计
            total_system_order_usercharge_money:"",//【服务费支出】系统收款：商家给平台的用户手续费合计

            total_own_order_charge_money:"",//【服务费支出】商家收款：商家给平台的服务费合计
            total_own_order_usercharge_money:"",//【服务费支出】商家收款：商家给平台的用户手续费合计

            total_system_attach_money:"",//【杂费收入】平台未给商家打的钱
            total_own_attach_money:"",//【杂费支出】商家未支付给平台的钱

            statement_note:"",//管理员备注
            settle_status:0,  
            pay_status:0,


             
            tabType:"goods",
            columns:[],//
            partnerTableData:[],//商家已经选择的数据
            tableData:[],//商家可以选择的数据
            meta:[],

            blindboxGoodsListVisible:false,
            couponListVisible:false,
            blindboxGoodsPieceListVisible:false,

            blindboxTicketContentNum:0,//参与盲盒商品的数量
            blindboxTicketContentType:0,//藏于盲盒商品的类型
            blindboxTicketContentId:0,//藏于盲盒商品的id

            blindstoreTicketContentVisible:false,
            blindstoreTicketContentUpdateVisible:false,
 
            selectedRowKeys: [], // Check here to configure the default column
            loading: false,
            content_type:1,//1 盲盒商品 2.盲盒碎片 3优惠券
            content_id:0,//盲盒内容id
            content_num:1,//申请数量
            blindstore_id:0,//盲盒id




        };
    //}


    start = () => {
        this.setState({ loading: true });
        // ajax request after empty completing
        setTimeout(() => {
          this.setState({
            selectedRowKeys: [],
            loading: false,
          });
        }, 1000);
      };

      onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
      };



    scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView(); }
        }
      }



    async componentDidMount() { 
        this.getDetail();  
    }

    async getDetail(){
        const id = this.props.location.pathname.substring(16)
        let res = await getBlindboxDetail(id); 
        this.setState({ 
            data: res.data,  
            blindstore_id:res.data.id,

        },()=>{
            this.HandleGetPartnerData('goods');
        })



    }

    handleChange = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (imageUrl, img) =>
                    this.setState({
                        imageUrl,
                        logo_file: img
                    }),
                );
            }
        }
    };



    //获取商家已经选择的表格数据
    async HandleGetPartnerData(type,page=1) { 

        //清空原数据
        this.setState({
                partnerTableData: [],
                partnerMeta: [],
        })

        let blindstore_id = this.state.blindstore_id;
        let partner_id = ''; //可不传 待优化

        if(type=='goods'){
            // let res = await getGoodsList(page, "", "")
            // this.setState({
            //     partnerTableData: res.data.data,
            //     meta: res.data.meta.pagination,
            // })


            let res = await getBlindboxTicketList(1,1,blindstore_id,partner_id,'');  
            this.setState({
                partnerTableData: res.data.data,
                partnerMeta: res.data.meta.pagination,
            }) 
            

        }else if(type == 'piece'){
            // let res = await getGoodsPieceList(page,  "", "")
            // this.setState({
            //     partnerTableData: res.data.data,
            //     meta: res.data.meta.pagination,
            // })

            let res = await getBlindboxTicketList(2,1,blindstore_id,partner_id,'');  
            this.setState({
                partnerTableData: res.data.data,
                partnerMeta: res.data.meta.pagination,
            }) 



        }else if(type == 'coupon'){
            // let res = await getCouponList(page,  "", "")
            // this.setState({
            //     partnerTableData: res.data.data,
            //     meta: res.data.meta.pagination,
            // })

            let res = await getBlindboxTicketList(3,1,blindstore_id,partner_id,'');  
            this.setState({
                partnerTableData: res.data.data,
                partnerMeta: res.data.meta.pagination,
            })  

        }
        
    }

    //删除ticket列表
    async handleDelete(data) {
        let res = await deleteBlindboxTicket(data.id)
        const dataSource = [...this.state.partnerTableData]; 
        this.setState({ partnerTableData: dataSource.filter(item => item.id !== data.id) });
    }

    // //修改ticket数量
    // updateBlindboxTicket = (item) => {
    //     const { id, is_show } = item
    //     let data = { "content_num": this.state.content_num}
    //     putBlindboxTicket(data, id).then(res => {
    //         //this.HandleGetData()
    //     })

    // }



    //获取表格数据
    async HandleGetData(type,page=1) { 

        //清空原数据
        this.setState({
            tableData: [],
            meta: [],
        })

        
        if(type=='goods'){ //盲盒商品
            let res = await getGoodsList(page, "&is_blindstore_spu=1", "")
            this.setState({
                tableData: res.data.data,
                meta: res.data.meta.pagination,
            })

        }else if(type == 'piece'){ //盲盒碎片
            let res = await getGoodsPieceList(page,  "&type=blindbox", "")
            this.setState({
                tableData: res.data.data,
                meta: res.data.meta.pagination,
            })

        }else if(type == 'coupon'){ //盲盒优惠券
            let res = await getCouponList(page,  "&type=blindbox", "")
            this.setState({
                tableData: res.data.data,
                meta: res.data.meta.pagination,
            })


        }
        
    }
 
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.SubmitData(values)
            }
        });
    };



    handleBlindstoreSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.blindstoreSubmitData(values)
            }
        });
    };


    handleBlindstoreUpdateSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.blindstoreSubmitUpdateData(values)
            }
        });
    };


     priceDeal(value){

        let price  = ""; 
        value =  ""+value ;  
        if (!!value[0] && value[0] === '-') { 
            price = "-" + currency_symbol + value.slice(1)
        } else {
            price = currency_symbol + value
        }

         
        
        return price;
    }


      priceDeal2(value){

        let price  = ""; 
        value =  ""+value ;  
        if (!!value[0] && value[0] === '-') { 
            price =   currency_symbol + value.slice(1)
        }   
        
        return price;
    }





    SubmitData(data) {
        let formData = new FormData()
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }

        } 

        if (this.state.logo_file) {
            formData.append("delivery_image", this.state.logo_file)
        }

 
       //特殊处理  
       /*
       if(data.order_status == 'be_delivery'){ 
            formData.append("status", 10)
       }else if(data.order_status == 'wait_delivery'){
            formData.append("status", 11)
       }
       */



       formData.append("status", 11);
       //formData.append("delivery_at", moment(data.delivery_at).format("YYYY-MM-DD") )
        
         
        const id = this.props.location.pathname.substring(13)

         

    };



    blindstoreSubmitData(data) {
        let formData = new FormData()
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            } 
        } 
 
       formData.append("blindstore_id", this.state.blindstore_id); 
       formData.append("content_id", this.state.content_id); 
       formData.append("content_type", this.state.content_type); 
       const id = this.props.location.pathname.substring(13) 
       addBlindboxTicket(id,formData).then(res => { 
            this.setState({
                blindstoreTicketContentVisible: false,
                content_type:this.state.content_type,
                content_id:0, 
                content_num:1,  

                //关闭申请内容的弹窗
                blindboxGoodsListVisible:false,
                blindboxGoodsPieceListVisible:false,
                couponListVisible:false, 

            } , () =>  { 
 
                    if(this.state.content_type == 1){
                        this.HandleGetPartnerData('goods',1)
                    }else if(this.state.content_type == 3){
                        this.HandleGetPartnerData('coupon',1) 
                    } 
                } 
            ) 
        })

    };


    blindstoreSubmitUpdateData(data) {
        let formData = new FormData()
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            } 
        }  
       // formData.append("blindstore_id", this.state.blindstore_id); 
       // formData.append("content_id", this.state.content_id); 
       // formData.append("content_type", this.state.content_type); 
       const id = this.state.ticket_id;  
        //let data = { "content_num": this.state.content_num}
        putBlindboxTicket(data, id).then(res => {
            //this.HandleGetData() 
            this.setState({
                blindstoreTicketContentUpdateVisible: false,
                content_type:1,
                content_id:0, 
                content_num:1,  
            }, () => { 
                switch(this.state.tabType){
                    case "goods" : this.HandleGetPartnerData('goods',1) ;break;
                    case "piece" : this.HandleGetPartnerData('piece',1) ;break;
                    case "coupon" : this.HandleGetPartnerData('coupon',1) ;break;
                    default:this.HandleGetPartnerData('goods',1) ;break;
                }   
            })  
        })

    };



 




    render() {
        const { getFieldDecorator } = this.props.form;

        const rowSelectionGoods = {
          onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          },
          getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
          }),
        };


        const rowSelectionCoupon = {

           //selectedRowKeys,
           //onChange: this.onSelectChange,
          onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          },
          getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
          }),
        };


        const rowSelectionPiece = {
          onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          },
          getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
          }),
        };





        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };


        //商家已经选择的盲盒商品列表
        const partnerBlindstoreGoodsListcolumns = [
            {
                title: "ID",
                dataIndex: 'id', 
                align: "center",
            },

            // {
            //     title: "内容类型",
            //     dataIndex: 'content_type', 
            //     align: "center",
            // },


            // {
            //     title: "内容ID",
            //     dataIndex: 'content_id', 
            //     align: "center",
            // },

         

 

            {
                title: "商品名称",  
                align: "center",
                render: (record) => {
                    return <span>{record.blindstoreData.name}</span>
                }
            },


             {
                title: "商品图片", 
                
                align: "center",
                render: (record) => {
                   return (
                        <img style={{ width: "50px", height: "50px", objectFit: 'cover' }} src={record.blindstoreData.image} alt="" />
                    )
                }
            },


            // {
            //     title: "商品数量",  
            //     align: "center",
            //     render: (record) => {
            //         return <span>-</span>
            //     }
            // },


            {
                title: "数量",
                dataIndex: 'content_num', 
                align: "center",
            },


            
            // {
            //     title: "状态", 
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <div>
            //                 {
            //                     record.end_status ?
            //                         <span style={{ color: "#ff0000" }}>已结束</span> :
            //                         <span style={{ color: "green" }}>正常</span>
            //                 }
            //             </div>
            //         )
            //     }
            // },

             {
                title: "操作", 
                align: "center",
                render: (record) => { 
                     return  ( 
                       <div> 

                        <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {   
                                this.setState({ 
                                    blindstoreTicketContentUpdateVisible: true, 
                                    ticket_id:record.id,
                                    content_num:record.content_num, 
                                })  
                            }}>
                           修改数量
                        </Button> 


                        <Popconfirm
                            title={intl.get('seat.table.btn.cancel.placeHorder')}
                            onConfirm={() => this.handleDelete(record)} >
                            <Button
                                style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {   
                                }}>
                               取消申请
                            </Button>
                        </Popconfirm>  


                        
                         
                        </div> 
                        ) 
                }
             } 


        ];

        //商家可以选择的盲盒商品列表 
        const blindstoreGoodsListcolumns = [
            {
                title: "ID",
                dataIndex: 'id', 
                align: "center",
            },
            



            {
                title: "商品名称",  
                align: "center",
                render: (record) => {
                    return <span>{record.name}</span>
                }
            },

            {
                title: "商品图片",  
                align: "center",
                render: (record) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: 'cover' }} src={record.image} alt="" />
                    )
                }
            },



             
            {
                title: "状态", 
                align: "center",
                render: (record) => {
                    return (
                        <div>
                            {
                                record.end_status ?
                                    <span style={{ color: "#ff0000" }}>已结束</span> :
                                    <span style={{ color: "green" }}>正常</span>
                            }
                        </div>
                    )
                }
            },

            {/*
                title: "数量",  
                align: "center",
                render: (record) => {
                    return  <div> 
                        <Form.Item
                            label=""
                        >
                        {getFieldDecorator( `sort[${record.id}]`, {initialValue: record.sort })
                        (<InputNumber min={0} max={100} 
                            onChange={(e) => { 
                                    this.setState({ 
                                        content_num: e.target.value, 
                                     }) 
                                }} 


                          /> )}  
                        
                        </Form.Item>
                    </div> 


                }
             */},


             {
                title: "操作", 
                align: "center",
                render: (record) => { 
                     return  ( 
                       <div> 
                        <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {  
                                this.setState({ 
                                    blindstoreTicketContentVisible: true, 
                                    content_id:record.id,
                                    content_type:1,
                                })  
                            }}>
                           申请
                        </Button> 
                        </div> 
                        ) 
                }
             } 



        ];



         //商家已经选择的盲盒碎片商品列表 
        const partnerBlindstoreGoodsPieceListcolumns = [
            {
                title: "ID",
                dataIndex: 'id', 
                align: "center",
            },
 
            // {
            //     title: "内容类型",
            //     dataIndex: 'content_type', 
            //     align: "center",
            // },


            // {
            //     title: "内容ID",
            //     dataIndex: 'content_id', 
            //     align: "center",
            // },

            


            {
                title: "商品名称",  
                align: "center",
                render: (record) => {
                    return <span>{record.blindstoreData.spu? record.blindstoreData.spu.name:"-"}</span>
                }
            },

            {
                title: "碎片名称",  
                align: "center",
                render: (record) => {
                    return <span>{record.blindstoreData.piece_name}</span>
                }
            },


            {
                title: "碎片图片",  
                align: "center",
                render: (record) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: 'cover' }} src={record.blindstoreData.image} alt="" />
                    )
                }
            },


            {
                title: "数量",
                dataIndex: 'content_num', 
                align: "center",
            },



            // {
            //     title: "碎片数量",  
            //     align: "center",
            //     render: (record) => {
            //         return <span>-</span>
            //     }
            // },


             
            // {
            //     title: "状态", 
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <div>
            //                 {
            //                     record.end_status ?
            //                         <span style={{ color: "#ff0000" }}>已结束</span> :
            //                         <span style={{ color: "green" }}>正常</span>
            //                 }
            //             </div>
            //         )
            //     }
            // },


                {
                title: "操作", 
                align: "center",
                render: (record) => { 
                     return  ( 
                       <div> 

                        <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {   
                                this.setState({ 
                                    blindstoreTicketContentUpdateVisible: true, 
                                    ticket_id:record.id,
                                    content_num:record.content_num, 
                                })  
                            }}>
                           修改数量
                        </Button> 



                        <Popconfirm
                            title={intl.get('seat.table.btn.cancel.placeHorder')}
                            onConfirm={() => this.handleDelete(record)}>
                            <Button
                                style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {   
                                }}>
                               取消申请
                            </Button>
                        </Popconfirm>  
                         
                        </div> 
                        ) 
                }
             } 


        ];





         //商家可以选择的盲盒碎片商品列表 
        const blindstoreGoodsPieceListcolumns = [
            {
                title: "ID",
                dataIndex: 'id', 
                align: "center",
            },
   
            {
                title: "商品名称",  
                align: "center",
                render: (record) => {
                    return <span>{record.spu?record.spu.name:"-"}</span>
                }
            },

            {
                title: "碎片名称",  
                align: "center",
                render: (record) => {
                    return <span>{record.piece_name}</span>
                }
            },


            {
                title: "碎片图片",   
                align: "center",
                render: (record) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: 'cover' }} src={record.image} alt="" />
                    )
                }
            }, 
            {
                title: "状态", 
                align: "center",
                render: (record) => {
                    return (
                        <div>
                            {
                                record.end_status ?
                                    <span style={{ color: "#ff0000" }}>已结束</span> :
                                    <span style={{ color: "green" }}>正常</span>
                            }
                        </div>
                    )
                }
            },

               {/*
                title: "数量",  
                align: "center",
                render: (record) => {
                    return  <div> 
                        <Form.Item
                            label=""
                        >
                        {getFieldDecorator( `sort[${record.id}]`, {initialValue: 1 })
                        (<InputNumber min={0} max={100} 
                            onChange={(e) => { 
                                    
                                }} 


                          /> )}  
                        
                        </Form.Item>
                    </div> 


                }
             */},


             {
                title: "操作", 
                align: "center",
                render: (record) => { 
                     return  ( 
                       <div>  
                           <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                           
                            onClick={() => {  
                                this.setState({ 
                                    blindstoreTicketContentVisible: true, 
                                    content_id:record.id,
                                    content_type:2,
                                })  
                            }} 
                            >
                           申请
                        </Button>  
                        </div> 
                        ) 
                }
             } 




        ];






        //商家可以选择的优惠券列表 
        const partnerCouponListcolumns = [
            {
                title: "ID",
                dataIndex: 'id', 
                align: "center",
            }, 

            // {
            //     title: "内容类型",
            //     dataIndex: 'content_type', 
            //     align: "center",
            // },


            // {
            //     title: "内容ID",
            //     dataIndex: 'content_id', 
            //     align: "center",
            // },

           




            {
                title: "优惠券名称",  
                align: "center",
                render: (record) => {
                    return <span>{record.blindstoreData.name}</span>
                }
            },
             

            {
                title: "折扣",  
                align: "center",
                render: (record) => {
                    let discount_on_fixed
                    if (record.blindstoreData.discount_type === "fixed") {
                        discount_on_fixed = currency_symbol + record.blindstoreData.discount_on_fixed
                    } else {
                        discount_on_fixed = (parseFloat(record.blindstoreData.discount_on_percentage) * 100).toFixed(0) + "%"
                    }
                    return discount_on_fixed
                }
            },
            

             {
                title: "数量",
                dataIndex: 'content_num', 
                align: "center",
            },



            // {
            //     title: "优惠券数量",  
            //     align: "center",
            //     render: (record) => {
            //         return <span>-</span>
            //     }
            // },


             
            // {
            //     title: "状态", 
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <div>
            //                 {
            //                     record.end_status ?
            //                         <span style={{ color: "#ff0000" }}>已结束</span> :
            //                         <span style={{ color: "green" }}>正常</span>
            //                 }
            //             </div>
            //         )
            //     }
            // },

            {
                title: "操作", 
                align: "center",
                render: (record) => { 
                     return  ( 
                       <div> 

                        <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => { 
                                this.setState({ 
                                    blindstoreTicketContentUpdateVisible: true, 
                                    ticket_id:record.id,
                                    content_num:record.content_num, 
                                })    
                            }}>
                           修改数量
                        </Button> 



                        <Popconfirm
                            title={intl.get('seat.table.btn.cancel.placeHorder')}
                            onConfirm={() => this.handleDelete(record)}>
                            <Button
                                style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {   
                                }}>
                               取消申请
                            </Button>
                        </Popconfirm>  
                         
                        </div> 
                        ) 
                }
             } 



        ];



    //商家可以选择的优惠券列表
    const couponListcolumns = [
            {
                title: "ID",
                dataIndex: 'id', 
                align: "center",
            },
 

            {
                title: "优惠券名称",  
                align: "center",
                render: (record) => {
                    return <span>{record.name}</span>
                }
            },
            // {
            //     title: "优惠券编号",
            //     dataIndex: 'unique_code', 
            //     align: "center",
            // },

            {
                title: "折扣",  
                align: "center",
                render: (record) => {
                    let discount_on_fixed
                    if (record.discount_type === "fixed") {
                        discount_on_fixed = currency_symbol + record.discount_on_fixed
                    } else {
                        discount_on_fixed = (parseFloat(record.discount_on_percentage) * 100).toFixed(0) + "%"
                    }
                    return discount_on_fixed
                }
            },
            
           
            {
                title: "状态", 
                align: "center",
                render: (record) => {
                    return (
                        <div>
                            {
                                record.end_status ?
                                    <span style={{ color: "#ff0000" }}>已结束</span> :
                                    <span style={{ color: "green" }}>正常</span>
                            }
                        </div>
                    )
                }
            },

               {/*
                title: "数量",  
                align: "center",
                render: (record) => {
                    return  <div> 
                        <Form.Item
                            label=""
                        >
                        {getFieldDecorator( `sort[${record.id}]`, {initialValue: record.sort })
                        (<InputNumber min={0} max={100} 
                            onChange={(e) => { 
                                    
                                }} 


                          /> )}  
                        
                        </Form.Item>
                    </div> 


                }
             */},


             {
                title: "操作", 
                align: "center",
                render: (record) => { 
                     return  ( 
                       <div> 
                        <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {  
                                this.setState({ 
                                    blindstoreTicketContentVisible: true, 
                                    content_id:record.id,
                                    content_type:3,
                                })  
                            }}>
                           申请
                        </Button>  

                        </div> 
                        ) 
                }
             } 



        ];








        const { 
            data, 
            order_gst ,
            order_gst_excl,
            order_gst_inc,
            order_gst_total,
            weight_total, 
            partner_message, 
            ItemData,
            deliveryVisible, 
            imageUrl ,
            delivery_company_name,
            delivery_partner_message,
            delivery_partner_note,
            delivery_no,
            delivery_image,
            delivery_track,
            delivery_at, 
            refundvisible,
            refundvisibleGoods,
            refund_goods_price,
            refund_message,
            refund_message_part,
            refund_spu_id,
            refund_spu_num,
            statementvisible,
            feeVisible,
            statement_note,
            settle_status,  
            pay_status,
            partnerTableData,
            partnerMeta,
            tableData,
            meta, 
            tabType, 
            blindboxGoodsListVisible,
            blindboxGoodsPieceListVisible,
            couponListVisible, 
            blindboxTicketContentNum,
            blindboxTicketContentType ,
            blindboxTicketContentId ,
            blindstoreTicketContentVisible, 
            blindstoreTicketContentUpdateVisible,
            loading, 
            selectedRowKeys,
            content_num,
            content_type,
            content_id,
            blindstore_id,

         } = this.state;

         
         const rowSelection = {
          selectedRowKeys,
          onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;



        let order_status = ""
        let scan_code_type = ""
        switch (data.order_status) {
            case "un_pay":
                order_status = intl.get("home.table.useStatus.un_pay");
                break;
            case "wait_order_receiving":
                order_status = intl.get("home.table.useStatus.wait_order_receiving");
                break;
            case "un_order_receiving":
                order_status = intl.get("home.table.useStatus.un_order_receiving");
                break;
            case "for_consumption":
                order_status = intl.get("home.table.useStatus.for_consumption");
                break;

            case "wait_delivery":
                order_status = "待配送";
                break;

            case "be_delivery":
                order_status = "配送中";
                break;
 

            case "finished":
                order_status = intl.get("home.table.useStatus.finished");
                break;
            case "canceled":
                order_status = intl.get("home.table.useStatus.canceled");
                break;
            case "rejected":
                order_status = intl.get("home.table.useStatus.rejected");
                break;
            case "refunded":
                order_status = intl.get("home.table.useStatus.refunded");
                break;
        }

        switch (data.scan_code_type) {
            case 1:
                scan_code_type = intl.get("nav.setting.seat")
                break;
            case 2:
                scan_code_type = intl.get("nav.goodsManagement.sales_promotion")
                break;
            case 3:
                scan_code_type = intl.get("nav.setting.seat") + "、" + intl.get("nav.goodsManagement.sales_promotion")
                break;
        }


        let spu_discount = data?.orderSpu?.data[0]?.attach_data?.spu_discount
        let oneUserWithdrawLog = data.oneUserWithdrawLog
        let userCoupon = data.userCoupon && data.userCoupon.data[0]
        let coupon_status = ""
     

   

        let orderSource = ""
        if (data?.order_source == 1) {
            orderSource = "店铺"
        } else if (data?.order_source == 2) {
            orderSource = "平台"
        }  

 

 
        return ( 

           
            <div>
                
 

                <Card> 
                    <Descriptions title="盲盒信息"> 
                         <Descriptions.Item label="名称">{data.name} 
                         </Descriptions.Item> 
                         <Descriptions.Item label="销售小区">{data.sales_area_sub_id}</Descriptions.Item>
                         <Descriptions.Item label="开始时间">{ data.start_at }  </Descriptions.Item>
                         <Descriptions.Item label="结束时间">{ data.end_at }  </Descriptions.Item> 
                    </Descriptions>

       
                    <Divider />

                     {
                       
                        <div style={{ marginBottom: "20px" }}>
                           
                            <div> 
                                {
                                     
                                    <Button
                                        style={{ marginRight: "5px", backgroundColor: tabType === "goods" ? "#58924d" : "", color: tabType === "goods" ? "#fff" : "rgba(0, 0, 0, 0.65)"  }}
                                        onClick={() => {
                                            this.setState({
                                                tabType: "goods"
                                            }, () => {
                                                this.HandleGetPartnerData('goods')
                                            })
                                        }}>
                                       申请参与的盲盒商品
                                    </Button>
                                }


                                {/*
                                     
                                    <Button
                                        style={{ marginRight: "5px", backgroundColor: tabType === "piece" ? "#58924d" : "", color: tabType === "piece" ? "#fff" : "rgba(0, 0, 0, 0.65)"  }}
                                        onClick={() => {
                                            this.setState({
                                                tabType: "piece"
                                            }, () => {
                                                this.HandleGetPartnerData('piece')
                                            })
                                        }}>
                                       申请参与的盲盒碎片
                                    </Button>
                                */}



                                
                                {
                                    
                                    <Button
                                        style={{marginRight: "5px", backgroundColor: tabType === "coupon" ? "#58924d" : "", color: tabType === "coupon" ? "#fff" : "rgba(0, 0, 0, 0.65)"  }}
                                        onClick={() => {

                                            this.setState({
                                                tabType: "coupon"
                                            }, () => {
                                                this.HandleGetPartnerData('coupon')
                                            })
                                        }}>
                                        申请参与的盲盒优惠券
                                    </Button>
                                } 
                            </div>
                        </div>
                    }



                      {
                        tabType === "goods" &&

                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" ,    flexDirection: 'column' }}>
                            <div style={{ textAlign: "right" ,paddingBottom:"15px"}} >
                            <Button
                                style={{marginRight: "5px", backgroundColor:  "", color:   "rgba(0, 0, 0, 0.65)"  }}
                                onClick={() => {

                                    this.setState({
                                       blindboxGoodsListVisible:true,
                                    }, () => {
                                        this.HandleGetData('goods')
                                    })
                                }}>
                                选择盲盒商品
                            </Button>
                            </div>
                        
                        <div>
                        <Table
                            rowKey={record => record.id} 
                            dataSource={partnerTableData}
                            columns={partnerBlindstoreGoodsListcolumns}
                            pagination={{
                                pageSize: partnerMeta && partnerMeta.per_page,
                                total: partnerMeta && partnerMeta.total
                            }}
                            onChange={(e) => { 
                                this.HandleGetPartnerData('blindbox',e.current)
                            }}
                        />
                        </div>


                        </div>

                        }



                        {
                        tabType === "piece" &&

                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" ,    flexDirection: 'column' }}>
                                <div style={{ textAlign: "right" ,paddingBottom:"15px"}} >
                                <Button
                                    style={{marginRight: "5px", backgroundColor:  "", color:   "rgba(0, 0, 0, 0.65)"  }}
                                    onClick={() => {

                                        this.setState({
                                           blindboxGoodsPieceListVisible:true,
                                        }, () => {
                                            this.HandleGetData('piece')
                                        })
                                    }}>
                                    选择盲盒碎片
                                </Button>
                                </div>
                            
                            <div>

                            <Table
                                rowKey={record => record.id}
                                dataSource={partnerTableData}
                                columns={partnerBlindstoreGoodsPieceListcolumns}
                                pagination={{
                                    pageSize: partnerMeta && partnerMeta.per_page,
                                    total: partnerMeta && partnerMeta.total
                                }}

                                 onChange={(e) => { 
                                    this.HandleGetPartnerData('piece',e.current)
                                }}


                            />
                            </div> 
                        </div>

                        }






                    {
                        tabType === "coupon"   &&

                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" ,    flexDirection: 'column' }}>
                        
                        <div style={{ textAlign: "right" ,paddingBottom:"15px"}} > 
                            <Button
                                style={{marginRight: "5px", backgroundColor:  "", color:   "rgba(0, 0, 0, 0.65)"  }}
                                onClick={() => {

                                    this.setState({
                                       couponListVisible:true, 
                                    }, () => {
                                        this.HandleGetData('coupon')
                                    })
                                }}>
                                选择盲盒优惠券
                            </Button>
                       </div>


                       <div>
                        <Table
                            rowKey={record => record.id}
                            dataSource={partnerTableData}
                            columns={partnerCouponListcolumns}
                            pagination={{
                                pageSize: partnerMeta && partnerMeta.per_page,
                                total: partnerMeta && partnerMeta.total
                            }}
                            onChange={(e) => { 
                                this.HandleGetPartnerData('coupon',e.current)
                            }}
                        />
                        </div>
                        </div>
                    }









 
                   
                </Card>




                <Modal
                    title="盲盒商品列表"
                    visible={this.state.blindboxGoodsListVisible}
                    footer={null}
                    width={"70%"} 
                    onOk={() => { 
                        
                    }}
                    onCancel={() => {
                        this.setState({ 
                            blindboxGoodsListVisible: false
                        })
                    }} 
                    >  

                    <div>
                    <div style={{ marginBottom: 16 }}>
                      <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
                        批量申请
                      </Button>
                      <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                      </span>
                    </div>


                    
                    <Table
                        rowSelection={rowSelectionGoods} 
                        rowKey={record => record.id}
                        dataSource={tableData}
                        columns={blindstoreGoodsListcolumns}
                        pagination={{
                            pageSize: meta && meta.per_page,
                            total: meta && meta.total
                        }}
                        onChange={(e) => {
                            //this.HandleGetCouponData(e.current)
                            this.HandleGetData('goods',e.current)
                        }}
                    />

                    </div>

                 </Modal> 



                 <Modal
                    title="盲盒碎片商品列表" 
                    width={"80%"}
                    visible={this.state.blindboxGoodsPieceListVisible}
                    footer={null}
                    onOk={() => { 
                        
                    }}
                    onCancel={() => {
                        this.setState({ 
                            blindboxGoodsPieceListVisible: false
                        })
                    }} 
                    >  
                    <div>
                    <div style={{ marginBottom: 16 }}>
                      <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
                        批量申请
                      </Button>
                      <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                      </span>
                    </div>
                    <Table
                        rowSelection={rowSelectionPiece}
                        rowKey={record => record.id}
                        dataSource={tableData}
                        columns={blindstoreGoodsPieceListcolumns}
                        pagination={{
                            pageSize: meta && meta.per_page,
                            total: meta && meta.total
                        }}
                        onChange={(e) => {
                            //this.HandleGetCouponData(e.current)
                            this.HandleGetData('piece',e.current)
                        }}
                    />
                    </div>


                 </Modal> 





                  
                <Modal
                    title="优惠券列表" 
                    width={"80%"}
                    visible={this.state.couponListVisible}
                    footer={null}
                    onOk={() => { 
                        
                    }}
                    onCancel={() => {
                        this.setState({ 
                            couponListVisible: false
                        })
                    }} 
                    >  
                    
                    <div>
                    <div style={{ marginBottom: 16 }}>
                      <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
                        批量申请
                      </Button>
                      <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                      </span>
                    </div>
                    <Table
                        rowSelection={rowSelectionCoupon}
                        rowKey={record => record.id}
                        dataSource={tableData}
                        columns={couponListcolumns}
                        pagination={{
                            pageSize: meta && meta.per_page,
                            total: meta && meta.total
                        }}
                        onChange={(e) => {
                            //this.HandleGetCouponData(e.current)
                            this.HandleGetData('coupon',e.current)
                        }}
                    />
                    </div>


                 </Modal> 


                 <Modal
                    title="申请参与盲盒"
                    visible={this.state.blindstoreTicketContentVisible}
                    zIndex={9999}
                    footer={null}
                    onOk={() => { 
                        
                    }}
                    onCancel={() => {
                        this.setState({ 
                            blindstoreTicketContentVisible: false
                        })
                    }} 
                    >  
                    <Form {...formItemLayout} onSubmit={this.handleBlindstoreSubmit} className=" ">

                    <Form.Item
                        label="数量"
                    >
                        {getFieldDecorator('content_num', {
                            rules: [{ required: false,  }],
                            initialValue: this.state.content_num
                        })(<Input 

                        placeholder="请输入数量" 
                            onChange={(e) => {
                                 this.setState({
                                    content_num: e.target.value
                                })
                            }}
                            />)} 
                    </Form.Item>

                    <Button
                            type="primary" 
                            htmlType="submit" 
                            style={{ marginRight: "10px", marginTop:"10px" }} 
                        >
                        确认
                    </Button>
                    


                    </Form> 

                 </Modal> 



                 <Modal
                    title="修改数量"
                    visible={this.state.blindstoreTicketContentUpdateVisible}
                    zIndex={9999}
                    footer={null}
                    onOk={() => { 
                        
                    }}
                    onCancel={() => {
                        this.setState({ 
                            blindstoreTicketContentUpdateVisible: false
                        })
                    }} 
                    >  
                    <Form {...formItemLayout} onSubmit={this.handleBlindstoreUpdateSubmit} className=" ">

                    <Form.Item
                        label="数量"
                    >
                        {getFieldDecorator('content_num', {
                            rules: [{ required: false,  }],
                            initialValue: this.state.content_num
                        })(<Input 

                        placeholder="请输入数量" 
                            onChange={(e) => {
                                 this.setState({
                                    content_num: e.target.value
                                })
                            }}
                            />)} 
                    </Form.Item>

                    <Button
                            type="primary" 
                            htmlType="submit" 
                            style={{ marginRight: "10px", marginTop:"10px" }} 
                        >
                        确认
                    </Button>
                    


                    </Form> 

                 </Modal> 








                <Modal
                    title="更改清单状态"
                    visible={statementvisible}

                    onCancel={() => {
                        this.setState({
                             statementvisible: false, 
                            //refund_message: ""
                        })
                    }}
                    onOk={() => { 
                        // OrderRefund(data.id, { refund_message }).then(res => {
                              

                        //     setTimeout(() => {
                        //          this.setState({
                        //             refundvisible: false
                        //         }, () =>  
                        //             this.getDetail() 
                        //         ) 
                        //      }, 3000)


                                 
     

                        // }).catch(error => {
                        //     this.setState({ 
                        //         refundvisible: false
                        //     })
                        // })


                    }}
                >
                    

                        <Form.Item
                            label="结算状态"
                        >
                            {getFieldDecorator('settle_status', {
                                rules: [{ required: false }],
                                initialValue:data.settle_status
                            })(<Radio.Group
                                 onChange={(e) => {
                                    this.setState({
                                        settle_status: e.target.value,
                                         
                                    })
                                }} 
                            >


                                <Radio  value={0}>未结清</Radio>
                                <Radio  value={1}>已结清</Radio>
                                <Radio  value={2}>/</Radio>
                                <Radio  value={3}>校验中</Radio>
                            </Radio.Group>)}
                        </Form.Item> 



                    <Form.Item
                            label="支付状态"
                        >
                            {getFieldDecorator('pay_status', {
                                rules: [{ required: false }],
                                initialValue:data.pay_status
                            })(<Radio.Group
                                onChange={(e) => {
                                    this.setState({
                                        pay_status: e.target.value,
                                         
                                    })
                                }} 



                            >
                                <Radio  value={0}>未付款</Radio>
                                <Radio value={1}>已付款</Radio>
                                <Radio  value={2}>/</Radio>
                                <Radio  value={3}>校验中</Radio>
                            </Radio.Group>)}
                        </Form.Item> 




                    <TextArea
                        value={statement_note}
                        placeholder="请输入管理员备注(仅限英文，商家可见)"
                        onChange={(e) => {
                            this.setState({
                                statement_note: e.target.value
                            })
                        }}
                    />


                </Modal>




                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" , alignItems:"center"}}> 
                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            返回
                        </Button>
                    </Popconfirm>  
                </div>
            </div>
           

        );
    }
}

//export default withRouter(OrderDetail)

export default withRouter(Form.create()(OrderDetail))