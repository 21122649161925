import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Input, Form, Button, Col, Row, Checkbox, Switch, message, Tooltip, Icon, Upload } from 'antd';
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")
const EditableContext = React.createContext();
function beforeUpload(file) {
    if (file.status === "removed") {
        return true
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

class EditableCell extends React.Component {

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps} >
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record[dataIndex],
                        })(<Input prefix={currency_symbol} />)}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

let SpecificationItemId = []
let columns = []


class TableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            GoodsSpecificationItem: "",
            dataSource: [],
            editingKey: '',
            SpecificationId: "",
            tempId: [],
            InitColumns: [
                {
                    title: intl.get('goods.list.sku'),
                    dataIndex: "price",
                    width: '15%',
                    align: "center",
                    editable: true,
                    render: (record) => {
                        return currency_symbol + record
                    }
                },
                {
                    title: intl.get('lang.list.isDefault'),
                    // dataIndex: "id_default",
                    width: '10%',
                    align: "center",
                    render: (record) => {
                        return <Switch checked={record.isDefault == "1"} onChange={() => {
                            if (this.state.editingKey) {
                                message.error(intl.get("nav.goodsManagement.save_sku_first"))
                            } else {
                                this.handleChange(record)
                            }

                        }} />
                    }
                },
                {
                    title: "sku图片",
                    width: '10%',
                    align: "center",
                    render: (record) => {
                        return <Upload
                        accept=".jpg,.png,.jpeg"
                        listType="picture-card"
                        onChange={(e)=>this.handleUpload(e,record)}
                        showUploadList={false}
                        className='editGoodUpload'
                        beforeUpload={() => {
                            return false;
                        }}
                    >
                        {
                            record.titlePic?<img src={record.titlePic} style={{width:104}} alt=""/>:<div>
                            <div className="ant-upload-text">Add Picture</div>
                        </div>
                        }
                            

                    </Upload>
                    }
                },
                // {
                //     title: 'sku库存',
                //     dataIndex: "stock",
                //     width: '20%',
                //     align: "center",
                //     editable: true,
                // },
                {
                    title: intl.get('home.table.operation'),
                    dataIndex: 'operation',
                    width: '20%',
                    align: "center",
                    render: (text, record) => {
                        const { editingKey } = this.state;
                        const editable = this.isEditing(record);
                        return editable ? (
                            <div>
                                <EditableContext.Consumer>
                                    {form => (
                                        <Button
                                            onClick={() => this.save(form, record)}
                                        >
                                            {intl.get('seat.table.btn.save')}
                                        </Button>
                                    )}

                                </EditableContext.Consumer>
                                <Button
                                    style={{ marginLeft: "5px" }}
                                    onClick={() => {
                                        this.setState({ editingKey: '' }, () => {
                                            this.props.getData(this.state.dataSource, this.state.SpecificationId, "", false)
                                        });
                                    }}
                                >
                                    {intl.get('seat.table.btn.cancel')}
                                </Button>
                            </div>
                        ) : (
                            <div>
                                {/* {
                                    record.isDefault == "0" && */}
                                <Button disabled={editingKey !== ''} onClick={() => this.edit(record)}>
                                    {intl.get('seat.table.btn.edit')}
                                </Button>
                                {/* } */}

                            </div>
                        );
                    },
                },
            ]
        };
    }





    componentDidMount() {
        const { temp } = this.props
        const GoodsSpecificationData = this.props.GoodsSpecificationData
        SpecificationItemId = []
        if (temp && temp.length > 0) {
            let tempId = []
            for (let i in temp) {
                tempId.push(temp[i].id)
                this.handleChooseGoodsSpecification(temp[i], GoodsSpecificationData)
            }
            this.setState({ tempId })
        }


    }

    componentWillUnmount() {
        this.setState = () => false;
    }


    isEditing = record => record.key === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    save(form, key) {
        let rrp = this.props.rrp
        const { SpecificationId } = this.state
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = [...this.state.dataSource];

            const index = newData.findIndex(item => key.key === item.key);
            if (index > -1) {
                const item = newData[index];

                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                if (!newData[index].price) {
                    message.error(intl.get("nav.goodsManagement.sku_null"))
                    return false
                }
                if (rrp) {
                    if (parseInt(rrp) < parseInt(newData[index].price)) {
                        message.error(intl.get("nav.goodsManagement.rrp_cannot_price"))
                        return false
                    }
                }


                this.props.getData(newData, SpecificationId, newData[index])
                this.setState({ dataSource: newData, editingKey: '' });
            }

        });
    }



    edit(key) {
        this.setState({ editingKey: key.key }, () => {
            this.props.getData(this.state.dataSource, this.state.SpecificationId, "", true)
        });

    }



    Zuhe = (data) => {
        let heads = data[0];
        for (var i = 1, len = data.length; i < len; i++) {
            heads = this.addNewType(heads, data[i]);
        }
        return heads;
    }

    addNewType = (heads, choices) => {
        let result = [];
        for (var i = 0, len = heads.length; i < len; i++) {
            if (heads[0].length) {

                for (var a = 0, lena = choices.length; a < lena; a++) {
                    let headeData = []
                    for (let k in heads[i]) {
                        headeData.push(heads[i][k])
                    }
                    headeData.push(choices[a]);
                    result.push(headeData)
                }

            } else {
                for (var j = 0, lenj = choices.length; j < lenj; j++) {
                    result.push([heads[i], choices[j]]);
                }
            }
        }
        return result;
    }

    //输出结果  
    printResult = (result) => {
        let data = []
        if (result) {
            for (let i = 0; i < result.length; i++) {
                data.push(result[i])
            }
        }
        this.setState({
            SkuData: data
        })
        return data
    }


    handleChooseGoodsSpecification = (data, GoodsSpecificationData) => {

        const { InitColumns } = this.state;
        const { spuSkuData } = this.props

        let SpecificationId = []
        const id = data.id
        if (SpecificationItemId.length === 0) {
            SpecificationItemId.push(id)

        } else {
            let Sindex = SpecificationItemId.indexOf(id)
            if (Sindex > -1) {
                SpecificationItemId.splice(Sindex, 1)
            } else {
                SpecificationItemId.push(id)
            }
        }
        this.setState({ tempId: SpecificationItemId })

        let TotleData = []
        let FzuheData = JSON.parse(JSON.stringify(GoodsSpecificationData))
        for (let i in FzuheData) {
            let Item = FzuheData[i].goodsSpecificationItem.data
            for (let k in Item) {
                if (SpecificationItemId.indexOf(Item[k].id) > -1) {
                    TotleData.push(Item[k])
                }
            }
        }



        // goods_specification_id相同的进行分组
        var TempData = []
        var temp = {}
        for (let item of TotleData) {
            SpecificationId.push(item.goods_specification_id)
            if (!temp[item.goods_specification_id]) {
                temp[item.goods_specification_id] = []
                TempData.push(temp[item.goods_specification_id])
            }
            temp[item.goods_specification_id].push(item)
        }




        let ResColumns = []
        for (let i in columns) {
            ResColumns.push(columns[i])
        }

        let LdataSource = []
        for (let i in GoodsSpecificationData) {
            let result = false
            let ResColumnState = false
            let goodsSpecificationItem = GoodsSpecificationData[i].goodsSpecificationItem.data

            for (let k in goodsSpecificationItem) {

                if (id === goodsSpecificationItem[k].id) {
                    if (ResColumns.length === 0) {
                        for (let i in InitColumns) {
                            ResColumns.push(InitColumns[i])
                        }
                    }

                    for (let j in ResColumns) {
                        if (ResColumns[j].title === GoodsSpecificationData[i].name) {
                            result = true
                        }
                    }

                    if (!result) {
                        const data = {
                            title: GoodsSpecificationData[i].name,
                            dataIndex: GoodsSpecificationData[i].id,
                            width: '8%',
                            align: "center",
                        }
                        ResColumns.unshift(data)

                    }


                    const TableData = this.printResult(this.Zuhe(TempData));
                    for (let y in TableData) {
                        let newData = {}
                        let ID = []
                        if (TableData[y].length) {
                            for (let z in TableData[y]) {
                                newData[TableData[y][z].goods_specification_id] = TableData[y][z].name
                                ID.push(TableData[y][z].id)
                            }
                            newData.key = "key" + y

                        } else {
                            newData[TableData[y].goods_specification_id] = TableData[y].name
                            newData.key = "key" + TableData[y].id
                            ID.push(TableData[y].id)
                        }

                        ID.sort((a, b) => { return a - b })
                        newData.Id = ID
                        newData.price = this.props.price
                        newData.stock = 0
                        newData.isDefault = "0"
                        LdataSource.push(newData)
                    }
                }


                if (SpecificationItemId.indexOf(goodsSpecificationItem[k].id) > -1) {
                    ResColumnState = true

                }

            }

            if (!ResColumnState) {
                for (let w in ResColumns) {
                    if (ResColumns[w].title === GoodsSpecificationData[i].name) {
                        ResColumns.splice(w, 1)
                    }
                }
            }
        }

        columns = ResColumns

        if (spuSkuData) {
            if (LdataSource.length === spuSkuData.length) {
                for (let i in spuSkuData) {
                    let stritems = spuSkuData[i].goods_specification_items.split(",")
                    stritems.sort((a, b) => { return Number(a) - Number(b) })
                    if (LdataSource[i].Id.toString() === stritems.toString()) {
                        LdataSource[i].spu_id = spuSkuData[i].spu_id
                        LdataSource[i].price = spuSkuData[i].price
                        LdataSource[i].stock = spuSkuData[i].stock
                        LdataSource[i].isDefault = spuSkuData[i].is_default
                    }

                }
            }
        }

        if (LdataSource.length > 0) {
            let hasDefault = false
            for (let k in LdataSource) {
                if (LdataSource[k].isDefault === 1) {
                    hasDefault = true
                }
            }
            if (!hasDefault) {
                LdataSource[0].isDefault = 1
            }
        }
        this.setState({
            dataSource: LdataSource,
            SpecificationId
        }, () => { this.props.getData(LdataSource, SpecificationId) });
    }

    handleChange = (item) => {
        let { dataSource } = this.state
        for (let i in dataSource) {
            if (dataSource[i].key === item.key) {
                dataSource[i].isDefault = "1"
            } else {
                dataSource[i].isDefault = "0"
            }
        }
        this.setState({ dataSource },
            () => { this.props.getData(dataSource, this.state.SpecificationId, item) })
    }
    handleUpload = async({file,fileList},item) => {
        let { dataSource } = this.state

        if (file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (file) {
            let IsOk = beforeUpload(file);
            if (IsOk) {
                for(let item of fileList){
                    item.preview = await getBase64(item.originFileObj);
                }
                
                for (let i in dataSource) {
                    if (dataSource[i].key === item.key) {
                        dataSource[i].titlePic = fileList[0].preview
                    }
                }
                this.setState({ dataSource },
                    () => { this.props.getData(dataSource, this.state.SpecificationId, item) })
            }
        }


    }

    render() {
        const GoodsSpecificationData = this.props.GoodsSpecificationData
        const { tempId, dataSource } = this.state
        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const Column = columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });
        return (
            <div>
                <Form.Item
                    label={
                        <span>
                            {intl.get('goods.list.spei')}
                            <Tooltip title={intl.get("goods.list.speciname_tips")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>
                    }
                >
                    {
                        GoodsSpecificationData.length !== 0 && GoodsSpecificationData.map(item => {
                            let goodsSpecificationItem = item.goodsSpecificationItem.data
                            if (goodsSpecificationItem.length > 0) {
                                return <div key={item.id}>
                                    <Row>
                                        <Col>{item.name}:</Col>
                                        {
                                            goodsSpecificationItem.map(data => {
                                                return <Col span={10} key={data.id}>
                                                    <Checkbox
                                                        checked={tempId.indexOf(data.id) !== -1}
                                                        onChange={() => this.handleChooseGoodsSpecification(data, GoodsSpecificationData)}>{data.name}</Checkbox>
                                                </Col>
                                            })
                                        }
                                    </Row>
                                </div>
                            }

                        })
                    }

                </Form.Item>
                <Form.Item label={intl.get('goods.list.good')}>
                    <EditableContext.Provider value={this.props.form}>
                        <Table
                            rowKey={record => record.key}
                            components={components}
                            // bordered
                            dataSource={dataSource}
                            columns={Column}
                            rowClassName="editable-row"
                            pagination={{
                                hideOnSinglePage: true
                            }}
                            className="waperBox"
                        />
                    </EditableContext.Provider>
                </Form.Item>
            </div>


        );
    }
}

export default withRouter(Form.create()(TableComponent))

