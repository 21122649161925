import intl from 'react-intl-universal';

export function data() {
    return [
        {
            "name": intl.get("nav.datastatics"),
            "icon": "appstore",
            "children": [
                {
                    "name": intl.get("nav.datastatics"),
                    "path": "/dataStatistics",
                }
            ],
        },
        {
            "name": intl.get('nav.user'),
            "icon": "team",
            "children": [
                {
                    "name": intl.get("nav.user"),
                    "path": "/user",
                },

                

                 
                
                // {
                //     "name": "用户反馈",
                //     "path": "/contactUs",
                // }


            ],
        },



       

        {
            "name": "会员",
            "icon": "crown",
            "children": [
                 

                  {
                    "name": "会员",
                    "children": [

                        {
                            "name": '会员策略',
                            "path": "/memberTypeSet",
                        },



                        {
                            "name": '会员成长值设置',
                            "path": "/memberLevelSet",
                        },

                        // {
                        //     "name": '等级成长值变更',
                        //     "path": "/memberLevelSetList",
                        // },

                        {
                            "name": '会员动态成长设置',
                            "path": "/memberLevelUpdateSet",
                        },



                        {
                            "name": '成长值设置',
                            "path": "/memberGroupValueSet",
                        },


                    ]
                },


                {
                    "name": "积分",
                    "children": [
                        {
                            "name": '积分兑换比例设置',
                            "path": "/userPointsExchangeSet",
                        },

                        {
                            "name": '积分兑换项设置',
                            "path": "/userPointsExchangeOption",
                        },{
                            "name": '积分设置',
                            "path": "/userPointsSet",
                        },{
                            "name": '积分策略',
                            "path": "/userPointsMoneySet",
                        },


                    ]
                },



                // {
                //     "name": "会员卡",
                //     "children": [

                //         {
                //             "name": '列表',
                //             "path": "/memberCardList",
                //         }, 

                //     ]
                // },


               // {
               //      "name": "会员卡订单",
               //      "children": [

               //          {
               //              "name": '列表',
               //              "path": "/memberCardOrderList",
               //          }, 

               //      ]
               //  },


              

            ],
        },



         {
            "name": "盲盒",
            "icon": "gift",
            "children": [
                 

                 {
                    "name": "盲盒",
                    "children": [

                        {
                            "name": '平台盲盒列表',
                            "path": "/BlindBoxList",
                        },  

                        // {
                        //     "name": '参与盲盒列表',
                        //     "path": "/BlindBoxParticipationList",
                        // }, 

                    ]
                },


                // {
                //     "name": "保证金",
                //     "path": "/EarnestMoney",
                // },

              

            ],
        },

 
        
        {
            "name": intl.get('nav.goodsManagement'),
            "icon": "trophy",
            "children": [
                {
                    "name": intl.get('nav.goodsManagement.goods.goodslist'),
                    "path": "/goodsList",
                },
                {
                    "name": intl.get('nav.goodsManagement.classify.classifyList'),
                    "path": "/cateList",
                },
                {
                    "name": intl.get('nav.goodsManagement.specifications'),
                    "path": "/goodsSpecificationList",
                },
                // {
                //     "name": intl.get('nav.goodsManagement.specifications'),
                //     "children": [
                //         {
                //             "name": intl.get('nav.goodsManagement.specifications'),
                //             "path": "/goodsSpecificationList",
                //         },
                //         {
                //             "name": intl.get('nav.goodsManagement.itemspecifications'),
                //             "path": "/goodsSpecificationItemList",
                //         }
                //     ],
                // },
                {
                    "name": intl.get('nav.goodsManagement.additional.list'),
                    "path": "/goodsAnnexTypeList",
                },

                {
                    "name": '商品Tag',
                    "path": "/GoodsTagList",
                },



                // {
                //     "name": intl.get('nav.goodsManagement.additional'),
                //     "children": [
                //         {
                //             "name": intl.get('nav.goodsManagement.additional.list'),
                //             "path": "/goodsAnnexTypeList",
                //         },
                //         {
                //             "name": intl.get('nav.goodsManagement.itemadditional.list'),
                //             "path": "/goodsAnnexTypeItemList",
                //         }
                //     ],
                // },
            ],
        },



        {
            "name": '严选',
            "icon": "trophy",
            "children": [
                {
                    "name": '严选列表',
                    "path": "/goodStrictList",
                },
               

 
            ],
        },




        {
            "name": intl.get('nav.event'),
            "icon": "deal",
            "children": [
                {
                    "name": intl.get('nav.goodsManagement.promotionalgoods.list'),
                    "path": "/promotionalgoodsList",
                },
            ]
        },
        {
            "name": intl.get('nav.goodsManagement.promotionalgoods.coupon'),
            "icon": "coupon",
            "children": [
                {
                    "name": intl.get('nav.goodsManagement.promotionalgoods.coupon'),
                    "path": "/couponList",
                },
            ]
        },

        {
            "name": intl.get('nav.orderManagement.list'),
            "icon": "snippets",
            "children": [
                {
                    "name": intl.get("nav.orderManagement.list"),
                    "path": "/OrderList",
                },

                {
                    "name": "订单退款",
                    "path": "/userOrderRefund"
                },


                {
                    "name": "过期天数",
                    "path": "/orderSetting",
                }


            ],
        },


        {
            "name": "Chat",
            "icon": "message",
            "children": [
                {
                    "name": "Chat列表",
                    "path": "/chatList",
                },

               

            ],
        },

   

        
        {
            "name": intl.get('setup.form.shop'),
            "icon": "shop",
            "children": [

                {
                    "name": intl.get('nav.setting.businessHours'),
                    "children": [
                        {
                            "name": intl.get('nav.setting.businessHours'),
                            "path": "/businessHours",
                        },
                        // {
                        //     "name": intl.get('nav.setting.public_holidays'),
                        //     "path": "/businessHoliday",
                        // },
                        {
                            "name": intl.get('nav.setting.holiday_business'),
                            "path": "/holidayBusinessList",
                        },
                    ]
                },

                {
                    "name": intl.get('nav.setting.notice.list'),
                    "children": [
                        {
                            "name": intl.get('nav.setting.notice.list'),
                            "path": "/AnnouncementList",
                        },
                        {
                            "name": intl.get('nav.setting.notice.system'),
                            "path": "/SystemAnnouncement",
                        },
                    ]
                },
                {
                    "name": intl.get("nav.album"),
                    "path": '/AlbumList',
                },
                {
                    "name": intl.get('nav.behavior'),
                    "path": "/behavior"
                },




                {
                    "name": intl.get('home.table.way.name_d'),
                    "children": [
                        {
                            "name": "消费方式",
                            "path": "/toStore"
                        },
                        {
                            "name": "店内消费",
                            "path": "/toStoreInstore",
                        },

                        {
                            "name": "到店自提",
                            "path": "/toStorePickup",
                        },


                    ]
                },





                {
                    "name": intl.get('setup.form.delivery'),
                    "children": [
                        {
                            "name": "配送方式",
                            "path": "/deliverySet"
                        },

                        {
                            "name": "送货",
                            "path": "/delivery"
                        },

                        
                        {
                            "name": "运费",
                            "path": "/freightList",
                        },
                    ]
                },
            ],
        },
        {
            "name": intl.get('nav.package'),
            "icon": "package",
            "children": [
                {
                    "name": intl.get('nav.package.list'),
                    "path": "/package",
                },
                // {
                //     "name": intl.get('nav.package.currentPackage'),
                //     "path": "/packageDetail",
                // },
                // {
                //     "name": intl.get('nav.package.packageLog.list'),
                //     "path": "/packageLog",
                // },
                {
                    "name": intl.get('setup.form.licence'),
                    "path": "/licence",
                },
            ],
        },
        {
            "name": intl.get('nav.account'),
            "icon": "user",
            "noShow": true,
            "children": [
                {
                    "name": intl.get('nav.settingMe'),
                    "children": [
                        {
                            "name": intl.get('nav.setting.base'),
                            "path": "/setup",
                        },
                        {
                            "name": intl.get('nav.setting.password'),
                            "path": "/editpassword",
                        },
                        {
                            "name": intl.get('nav.setting.management'),
                            "path": "/admin",
                            "icon": "user", 
                        },
                        {
                            "name": intl.get("nav.setting.lang"),
                            "path": "/languageList",
                        },
                       
                       
                    ],
                },
                {
                    "name": intl.get('nav.money'),
                    "children": [


                        {
                            "name": "平台订单",
                            "path": '/OrderFeeList'
                        },

                        
                        {
                            "name": intl.get('home.table.partner_charge_money'),
                            "path": '/OrderChargeFeeList'
                        },



                        

 
                        
                        {
                            "name": intl.get('nav.CostFlow.other'),
                            "path": '/AnnualFeeList'
                        },


                        {
                            "name": "结算清单",
                            "path": '/statementList'
                        },



                        {
                            "name": intl.get("nav.PaySet"),
                            "path": '/PaymentConfig'
                        },

                        {
                            "name": '银行卡管理',
                            "path": '/PaymentCard'
                        }
                    ],
                },

                {
                    "name": intl.get("nav.setting.SiteLocation"),
                    "children": [
                        {
                            "name": intl.get("setup.form.region"),
                            "path": "/SiteCountry",
                        },
                        {
                            "name": intl.get("nav.setting.SiteSuburb"),
                            "path": "/salesArea",
                        },
                    ]
                },
                {
                    "name": intl.get("nav.plugin.third_party_video_platform"),
                    "path": "/upLiveToken",
                },

                {
                    "name": "客服",
                    "path": "/customerService",
                },


                {
                    "name": intl.get("helpdoc"),
                    "path": "/documentList",
                },

                {
                    "name": "操作日志",
                    "path": "/operationLog",
                },

                
            ],
        },


        {
            "name": intl.get("nav.plugin"),
            "icon": "interaction",
            "children": [
                {
                    "name": intl.get("nav.plugin"),
                    "children": [
                        
                        /*
                        {
                            "name": intl.get("nav.plugin.currency"),
                            "path": "/currencyPlug",
                        },
                        */

                       

                        {
                            "name": intl.get("nav.goodsManagement.goods"),
                            "path": "/goodsPlug",
                        },


                        {

                            "name": intl.get('nav.setting.seat'),
                            "path": "/seatList",
                        },

                
                        {
                            "name": intl.get("nav.setting.printer"),
                            "path": "/PrinterList",
                        },

                        /*{
                            "name": "接单",
                            "path": "/receivingPlug",
                        },*/


                        {
                            "name": "会员",
                            "path": "/memberPlug",
                        },



                        {
                            "name": "满包邮",
                            "path": "/deliveryFree",
                        },


                        





                        
                        /*{
                            "name": intl.get("nav.plugin.deliveryPlug"),
                            "path": "/deliveryPlug",
                        },*/

                          


                    ],
                },


                




                {
                   /* "name": intl.get("home.table.way.name_d"),
                    "children": [
                          

                          {
                            "name": intl.get('nav.setting.seat'),
                            "path": "/seatList",
                         },



                        {
                            "name": intl.get("home.table.way.name_d"),
                            "path": "/toStorePlug",
                        },
                         



                         {
                            "name": intl.get("nav.plugin.deliveryPlug"),
                            "path": "/deliveryPlug",
                        },  
                        
                    ], */


                }




            ],
        },
        {
            "name": intl.get("nav.download.app_download"),
            "icon": "mobile",
            "children": [
                {
                    "name": intl.get("nav.download.app_download"),
                    "path": "/download",
                }
            ],
        },

    ]

}
