import React from 'react'
import { withRouter } from 'react-router-dom'
import "./setup.less"
import { Form, Input, Upload, Icon, message, Button, List, Select, Modal, Card, Tooltip, Switch } from 'antd';
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import { getPartner, UpdateShopMessage, getIsSuper, QueryAddress, StyleList } from "../../action/authAction";
import { getEditorContent } from "../../action/AdAction"
import intl from 'react-intl-universal';
import VideoImg from "../../img/iconVideo.png"
import { getLangType, BaseUrl } from "../../util/function";
import { API } from '../../util/api';
let { Option } = Select
const { TextArea, Search } = Input;
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}


class SetUp extends React.Component {

    state = {
        userData: "",
        logo_file: "",
        imageUrl: "",
        foodLicenseImg: "",
        foodLicenseFileList: "",
        weChatFileList: "",
        businessLicenseImageUrl: "",
        businessLicenseImage: "",
        address: "",
        suburbsList: "",
        isShow: true,
        CodeImage: "",
        AdImageUrl: "",
        AdImage: "",
        AdVideo: "",
        AdVideoUrl: "",
        editorState: BraftEditor.createEditorState(null),
        styleList: [],
        randomCode: "",
        IsSuper: false,
        is_show_partner_address: false,
        verify_code_key: "",
        verify_code: "",
        emailValue: ""
    };

    Init = async () => {
        let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
            a = t.length,
            n = "";
        for (let i = 0; i < 32; i++) {
            n += t.charAt(Math.floor(Math.random() * a));
        }
        let styleList = await StyleList()
        let res = await getPartner()
        let data = res.data
        let IsSuper = await getIsSuper()
        let fileList = [];
        let imgdata = data?.partnerFoodLicense?.data
        for (let i in imgdata) {
            fileList.push({
                uid: imgdata[i].id,
                name: imgdata[i].id,
                status: 'done',
                url: imgdata[i].image,
                sort: imgdata[i].sort === 0 ? "0" : imgdata[i].sort
            });
        }
        let weChatList = []
        let wechatData = data?.partnerWechatImage?.data

        for (let i in wechatData) {
            weChatList.push({
                uid: wechatData[i].id,
                name: wechatData[i].id,
                status: 'done',
                url: wechatData[i].image,
                sort: wechatData[i].sort === 0 ? "0" : wechatData[i].sort
            });
        }

        this.setState({
            userData: data,
            imageUrl: data.logo,
            businessLicenseImageUrl: data.business_license,
            AdImageUrl: data.recruitment && data.recruitment.image,
            AdVideoUrl: data.recruitment && data.recruitment.video,
            styleList: styleList.data.data,
            randomCode: n,
            foodLicenseFileList: fileList,
            weChatFileList: weChatList,
            IsSuper: IsSuper.data === 1 ? true : false,
            is_show_partner_address: data.is_show_partner_address === 1 ? true : false,
        }, () => {
            this.ChangeEditorData(data.pay_info)
            this.CheckIsShowCode()
        })
    }


    componentDidMount() {
        this.Init();
    }


    ChangeEditorData = (data) => {
        getEditorContent(data).then(res => {
            this.setState({
                editorState: BraftEditor.createEditorState(res.data)
            })
        }).catch(error => {
        })
    }

    CheckIsShowCode = () => {
        let { name, phone, partnerAddress, title, email, id } = this.state.userData
        let param = ""
        param += "partner_id" + id
        let codeUrl = `${BaseUrl()}/api/getWxCode?scene=${param}&page=pages/index/init&width=10&partner_id=${id}`
        if (name && phone && title && email && partnerAddress && partnerAddress.streets && partnerAddress.suburbs) {
            this.setState({ CodeImage: codeUrl })
        }
    }


    handleChange = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (imageUrl, img) =>
                    this.setState({
                        imageUrl,
                        logo_file: img
                    }),
                );
            }
        }
    };


    handleChangeAd = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (AdImageUrl, img) =>
                    this.setState({
                        AdImageUrl,
                        AdImage: img
                    }),
                );
            }
        }
    };

    handleVideoChange = async (info) => {
        let isLt10M = info.file.size / 1024 / 1024 < 5
        if (!isLt10M) {
            message.error("Upload failed: video file exceeds 5 MB.Please shorten the video to 5-10 seconds and upload again.");
            return false;
        }
        let fileListData = info.file
        let videoNum = fileListData.type.indexOf("video")
        if (videoNum > -1) {
            this.setState({
                AdVideo: fileListData
            })
        } else {
            message.error("Please Select Video!")
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.pay_info = this.state.editorState.toHTML()
                values.is_show_partner_address = this.state.is_show_partner_address ? "1" : "0"
                if (this.state.userData.email !== values.email) {
                    values.verify_code_key = this.state.verify_code_key
                    values.verify_code = this.state.verify_code
                } else {
                    delete values.email
                }

                values["partner_bank_account[name]"] = values.partner_bank_account_name ? values.partner_bank_account_name : ""
                values["partner_bank_account[bsb]"] = values.partner_bank_account_bsb ? values.partner_bank_account_bsb : ""
                values["partner_bank_account[number]"] = values.partner_bank_account_number ? values.partner_bank_account_number : ""
                this.SubmitData(values)
            }
        });
    };

    async SubmitData(data) {
        const { logo_file, imageUrl, AdImage, AdVideo, businessLicenseImage, foodLicenseImg } = this.state
        let formData = new FormData()
        for (let i in data) {
            // if (data[i]) {
            formData.append(i, data[i])
            // }
        }
        if (logo_file || imageUrl) {
            logo_file && formData.append("logo_file", logo_file)
        } else {
            message.error(intl.get('setup.form.logo.placehorder'))
        }

        AdImage && formData.append("recruitment[image]", AdImage)
        AdVideo && formData.append("recruitment[video]", AdVideo)
        foodLicenseImg && formData.append("food_license_file", foodLicenseImg)
        foodLicenseImg && formData.append("business_license_file", businessLicenseImage)
        await UpdateShopMessage(formData)
        this.Init();


    }



    handleChangeEditor = (editorState) => {
        this.setState({ editorState })
    }

    checkPsd(rule, value, callback) {
        let abstract = this.props.form.getFieldValue('abstract');
        let len = 0;
        for (let i = 0; i < abstract.length; i++) {
            if (abstract.charCodeAt(i) > 127 || abstract.charCodeAt(i) == 94) {
                len += 2;
            } else {
                len++;
            }
        }
        if (len > 80) {
            callback(new Error(intl.get("goods.list.name.horder.max")));
        } else {
            callback();
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );
        const uploadAdButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 12,
                },
            },
        };


        const { imageUrl, userData, isShow, suburbsList, AdImageUrl, AdVideoUrl, styleList, AdVideo, randomCode, businessLicenseImageUrl, foodLicenseFileList, weChatFileList, IsSuper, is_show_partner_address } = this.state;
        if (userData) {
            return (
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView" style={{ paddingTop: "20px" }}>
                    <Card style={{ margin: "30px" }}>
                        <Form.Item
                            label="ID"
                        >
                            <Input value={userData.id} disabled />
                        </Form.Item>
                        <Form.Item
                            label={intl.get('setup.form.user')}
                        >
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: intl.get('seat.table.please'), whitespace: true }],
                                initialValue: userData.name
                            })(<Input disabled />)}
                        </Form.Item>

                    </Card>


                    <Card style={{ margin: "30px" }}>
                        <Form.Item
                            label={intl.get('setup.form.title')}
                        >
                            {getFieldDecorator('title', {
                                // rules: [{ required: true, message: intl.get('seat.table.please'), whitespace: true }],
                                initialValue: userData.title
                                // })(<Input disabled={!IsSuper} />)}
                            })(<Input />)}
                        </Form.Item>



                        <Form.Item
                            label="Customer Code"
                        >
                            {getFieldDecorator('customer_code', {
                                // rules: [{ required: true, message: intl.get('seat.table.please'), whitespace: true }],
                                initialValue: userData.customer_code
                                // })(<Input disabled={!IsSuper} />)}
                            })(<Input disabled />)}
                        </Form.Item>






                        <Form.Item
                            label={intl.get('setup.form.phone')}
                        >
                            {getFieldDecorator('phone', {
                                // rules: [{ required: true, message: intl.get('seat.table.please'), whitespace: true }],
                                initialValue: userData.phone
                            })(<Input />)}
                        </Form.Item>




                        <Form.Item
                            label={intl.get('setup.form.otherPhone')}
                        >
                            {getFieldDecorator('reserve_phone', {
                                initialValue: userData.reserve_phone
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label={<span>{intl.get('setup.form.streets')}</span>}
                        >
                            {getFieldDecorator('streets', {
                                rules: [{ required: false }],
                                initialValue: userData.partnerAddress && userData.partnerAddress.streets
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label={<span>{intl.get('setup.form.suburbs')}</span>}
                        >
                            {getFieldDecorator('suburbs', {
                                rules: [{ required: true }],
                                initialValue: userData.partnerAddress && userData.partnerAddress.suburbs,
                            })(<Input disabled onChange={(e) => {
                                e.persist()
                                let key = e.target.value
                                if (key.length > 2) {
                                    QueryAddress(key).then(res => {
                                        this.setState({
                                            suburbsList: res.data,
                                            isShow: true
                                        })
                                    })
                                }
                            }}
                            />)}
                            {
                                suburbsList && isShow && <List
                                    size="small"
                                    bordered
                                    dataSource={suburbsList}
                                    renderItem={item =>
                                        <List.Item onClick={() => {
                                            this.props.form.setFieldsValue({
                                                suburbs: item.name + ", " + item.state.abbreviation + ", " + item.postcode
                                            })
                                            this.setState({ isShow: false })
                                        }}>{item.name}, {item.state.abbreviation}, {item.postcode}</List.Item>
                                    }
                                />
                            }

                        </Form.Item>

                        <Form.Item
                            label={intl.get('setup.form.ShowAddress')}
                        >
                            <Switch checked={is_show_partner_address} onChange={() => {
                                this.setState({
                                    is_show_partner_address: !is_show_partner_address,
                                })
                            }} />
                        </Form.Item>
                        <Form.Item
                            label={<span>{intl.get('setup.form.domain')}</span>}
                        >
                            <Input disabled value={userData.real_domain} />
                        </Form.Item>
                    </Card>







                   <Card style={{ margin: "30px" ,display:"none"}}>

                   
                        <Form.Item
                            label={<span>州</span>}
                        > 
                            <Input  disabled value={userData.state.state}  />
                        </Form.Item>





                        <Form.Item
                            label={<span>所在联合大区</span>}
                        >   
                             <Input  disabled value={userData.salesAreaUnion?userData.salesAreaUnion.name:""}  />
                           
                        </Form.Item>





                        <Form.Item
                            label={<span>所在销售大区</span>}
                        >
                              <Input  disabled value={userData.salesArea ? userData.salesArea.name : ""} />   
                        </Form.Item>





                        {
                            
                            <Form.Item
                                label="销售小区"
                            >   

                              <Input disabled value={userData.salesAreaSub ? userData.salesAreaSub.name : ""}  />   
                               
                            </Form.Item>
                        }

                   </Card>



















                    <Card style={{ margin: "30px" }}>
                        {/* <Form.Item label={intl.get('setup.form.email')}>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: intl.get('setup.form.email.placehorder'),
                                    },

                                ],
                                initialValue: userData.email
                            })(<Input />)}
                        </Form.Item> */}
                        <Form.Item label={intl.get('setup.form.email')}>
                            <div>
                                {getFieldDecorator('email', {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: intl.get('setup.form.email.placehorder'),
                                        },
                                    ],
                                    initialValue: userData.email
                                })(
                                    <Input
                                        placeholder={intl.get("setup.form.email_enter")}
                                        onChange={(e) => {
                                            e.persist()
                                            this.setState({
                                                emailValue: e.target.value
                                            })
                                        }}
                                    />
                                )}

                                <Search
                                    placeholder={intl.get("setup.form.code_enter")}
                                    enterButton="Send"
                                    onChange={(e) => {
                                        e.persist()
                                        this.setState({
                                            verify_code: e.target.value
                                        })
                                    }}
                                    onSearch={value => {
                                        let data = { "provider": "email", "email": this.state.emailValue }
                                        fetch(API.EmailBASEURL + API.verifyCode, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                            body: JSON.stringify(data),
                                        })
                                            .then(response => response.json())
                                            .then(data => {
                                                this.setState({
                                                    verify_code_key: data.verify_code_key
                                                })
                                                message.success(intl.get("summit.success"))
                                            })
                                            .catch((error) => {
                                                console.error('Error:', error);
                                            });

                                    }}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item>

                        </Form.Item>
                        <Form.Item label={<span>
                            {intl.get('setup.form.orderemail')}
                            <Tooltip title={intl.get("setup.form.orderemail_tips")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>}>
                            {getFieldDecorator('order_email', {
                                // rules: [
                                //     {
                                //         type: 'email',
                                //         message: intl.get('setup.form.email.placehorder'),
                                //     },

                                // ],
                                initialValue: userData.order_email
                            })(<Input />)}
                        </Form.Item>



                        <Form.Item
                            label={<span>{intl.get('setup.form.abstract')}</span>}
                        >
                            {getFieldDecorator('abstract', {
                                rules: [
                                    { required: false },
                                    { validator: (rule, value, callback) => { this.checkPsd(rule, value, callback) } }
                                ],
                                initialValue: userData.abstract
                            })(<TextArea />)}
                        </Form.Item>


                    </Card>
                    {/* <Card style={{ margin: "30px" }}>
                        {
                            !userData.rate &&
                            <div>
                                <Form.Item
                                    label={intl.get("package.rate")}
                                >
                                    {getFieldDecorator('package_rate', {
                                        initialValue: userData.package.rate
                                    })(<Input disabled min={0} style={{ width: '100%' }} suffix="%" />)}
                                </Form.Item>
                                <Form.Item
                                    label={intl.get("package.ActivityServiceFee")}
                                >
                                    {getFieldDecorator('package_activity_service_rate', {
                                        initialValue: userData.package.activity_service_rate
                                    })(<Input disabled min={0} style={{ width: '100%' }} suffix="%" />)}
                                </Form.Item>
                            </div>
                        }

                        {
                            userData.rate &&
                            <Form.Item
                                label={intl.get("setup.form.Merchant_general_service_fee")}
                            >
                                {getFieldDecorator('rate', {
                                    rules: [{ required: false }],
                                    initialValue: parseFloat(userData.rate) * 100
                                })(<Input disabled min={0} style={{ width: '100%' }} suffix="%" />)}
                            </Form.Item>
                        }
                        {
                            userData.activity_service_rate &&
                            <Form.Item
                                label={intl.get("setup.form.Merchant_service_fee")}
                            >
                                {getFieldDecorator('activity_service_rate', {
                                    rules: [{ required: false }],
                                    initialValue: parseFloat(userData.activity_service_rate) * 100
                                })(<Input disabled min={0} style={{ width: '100%' }} suffix="%" />)}
                            </Form.Item>
                        }
                        {
                            userData.order_fixed_fee &&
                            <Form.Item
                                label={intl.get("package.fee")}
                            >
                                <Input prefix="$" value={userData.order_fixed_fee} disabled />
                            </Form.Item>
                        }


                    </Card> */}

                    <Card style={{ margin: "30px" }}>
                        {/* <Form.Item
                        label={<span>{intl.get('setup.form.time')}</span>}
                    >
                        {getFieldDecorator('announcement', {
                            rules: [{ required: false }],
                            initialValue: userData.announcement
                        })(<TextArea
                            autoSize={{ minRows: 3, maxRows: 5 }}
                        />)}
                    </Form.Item> */}
                        <Form.Item
                            label={<span>{intl.get('setup.form.company')}</span>}
                        >
                            {getFieldDecorator('company', {
                                initialValue: userData.company
                            })(<Input />)}
                        </Form.Item>

                        <Form.Item
                            label={<span>{intl.get('setup.form.abn')}</span>}
                        >
                            {getFieldDecorator('abn', {
                                initialValue: userData.abn
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label={<span>{intl.get('home.table.order_refund.bank')}</span>}
                        >
                            {getFieldDecorator('partner_bank_account_name', {
                                initialValue: userData && userData.partnerBankAccount && userData.partnerBankAccount.name
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label={<span>{intl.get('home.table.order_refund.bsb')}</span>}
                        >
                            {getFieldDecorator('partner_bank_account_bsb', {
                                initialValue: userData && userData.partnerBankAccount && userData.partnerBankAccount.bsb
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label={<span>{intl.get('home.table.order_refund.account')}</span>}
                        >
                            {getFieldDecorator('partner_bank_account_number', {
                                initialValue: userData && userData.partnerBankAccount && userData.partnerBankAccount.number
                            })(<Input />)}
                        </Form.Item>
                        {
                            businessLicenseImageUrl &&
                            <Form.Item label={<span>{intl.get('setup.form.license')}</span>}>
                                <img src={businessLicenseImageUrl} alt="avatar" style={{ width: '150px', objectFit: 'cover' }} />
                            </Form.Item>
                        }
                        {
                            foodLicenseFileList.length > 0 &&
                            <Form.Item label={<span>{intl.get('setup.form.licensefile')}</span>}>
                                <div>
                                    {
                                        foodLicenseFileList.map(item => {
                                            return <img src={item.url} style={{ width: "100px", marginLeft: "10px" }} />
                                        })
                                    }
                                </div>
                            </Form.Item>
                        }
                        {
                            weChatFileList.length > 0 &&
                            <Form.Item label={<span>{intl.get('setup.form.wechatfile')}</span>}>
                                <div>
                                    {
                                        weChatFileList.map(item => {
                                            return <img src={item.url} style={{ width: "100px", marginLeft: "10px" }} />
                                        })
                                    }
                                </div>
                            </Form.Item>
                        }


                        <Form.Item
                            label={<span>
                                {intl.get('setup.form.logo')}
                                <Tooltip title={intl.get("nav.goodsManagement.goods.prompt")}>
                                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip>

                            </span>}
                        >
                            <Upload
                                name="avatar"
                                accept=".jpg,.png,.jpeg"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={this.handleChange}
                            >
                                {!!imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            label={<span>
                                {intl.get('setup.form.recruitmentImg')}
                                <Tooltip title={intl.get("nav.goodsManagement.goods.prompt")}>
                                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip>
                            </span>}
                        >
                            <Upload
                                name="avatar"
                                accept=".jpg,.png,.jpeg"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={this.handleChangeAd}
                            >
                                {!!AdImageUrl ? <img src={AdImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadAdButton}
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            label={<span>
                                {intl.get('setup.form.recruitmentVideo')}
                                <Tooltip title={intl.get("setup.form.recruitmentVideo.tips")}>
                                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip>
                            </span>}
                        >
                            {!!AdVideoUrl && <div>
                                <video src={AdVideoUrl} style={{ width: "100%" }} controls />
                                <Button onClick={() => this.setState({ AdVideoUrl: "" })}>{intl.get("home.table.operation.btn.delete")}</Button>
                            </div>}
                            {
                                !AdVideoUrl && <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                    onChange={this.handleVideoChange}
                                >

                                    {!!AdVideo ? <img src={VideoImg} alt="avatar" style={{ width: '100%' }} /> : uploadAdButton}
                                </Upload>
                            }


                        </Form.Item>
                        <Form.Item
                            label={intl.get('ad.form.style')}
                        >
                            {getFieldDecorator('style_id', {
                                initialValue: userData && userData.style_id
                            })(
                                <Select>
                                    {styleList && styleList.map(el => <Select.Option key={el.id} value={el.id}>
                                        <div style={{ display: "flex", alignItems: "center", height: "30px" }}>
                                            <span style={{ width: "20px", height: "20px", backgroundColor: el.color, marginRight: "15px" }}></span>
                                            <span>{el.color}</span>
                                        </div>
                                    </Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item
                            label={<span>{intl.get('setup.form.info')}</span>}
                        >
                            <div style={{
                                // width: "80%",
                                border: "1px solid #dedede",
                            }}>
                                <BraftEditor
                                    value={this.state.editorState}
                                    language={Number(getLangType()) == 1 ? "zh" : "en"}
                                    onChange={this.handleChangeEditor} />
                            </div>
                        </Form.Item>
                    </Card>

                    <Form.Item {...tailFormItemLayout}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {intl.get('cashwithdrawal.form.submit')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        } else {
            return null
        }

    }
}

export default withRouter(Form.create()(SetUp))
