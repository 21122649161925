import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Switch, Tooltip, Icon,Select } from 'antd';
import { getPartner, UpdateShopMessage, GetConfigSet } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class Behavior extends React.Component {

    state = {
        min_pay_fee_on_online: "",
        is_charge_packing_fee: false,
        business_status: true,
        is_allday_order: true,
        is_partner_moon_enable: true,
        is_partner_gst: true,
        is_open_recommend: true,
        is_user_address: true,
        is_force_user_address: true,
        is_holiday_business: true,
        is_open_share: true,
        is_open_spu_many_help: false,
        order_expire: "",
        activity_order_expire: "",
        is_audit:false,
        is_open_moon_enable:false,
        is_open_gst:false,
        unpay_wait_time:0,

        is_order_receiving: true,
        // stop_order_time, 
        // receiving_order_time, 


        TimeData:[
                {   'id':'10',
                    'name':'10 mins'
                },

                {   'id':'15',
                    'name':'15 mins'
                },

                {'id':'30',
                    'name':'30 mins'
                },

                {'id':'60',
                    'name':'60 mins'
                },



                ]


    };

    async componentDidMount() {
        let res = await getPartner()
        let configData = await GetConfigSet()
        let data = res.data
        this.setState({
            min_pay_fee_on_online: configData.data.data.find(el => el.key === "min_pay_fee_on_online").value,
            // is_charge_packing_fee: data.is_charge_packing_fee === "1" ? true : false,
            business_status: data.business_status == 1 ? true : false,
            is_allday_order: data.is_allday_order == 1 ? true : false,
            is_partner_moon_enable: data.is_partner_moon_enable == 1 ? true : false,
            is_partner_gst: data.is_partner_gst == 1 ? true : false,
            is_open_recommend: data.is_open_recommend == 1 ? true : false,
            // is_user_address: data.is_user_address == 1 ? true : false,
            // is_force_user_address: data.is_force_user_address == 1 ? true : false,
            is_holiday_business: data.is_holiday_business == 1 ? true : false,
            is_open_share: data.is_open_share == 1 ? true : false,
            is_authorization_code: data.is_authorization_code == 1 ? true : false,
            is_audit: data.is_audit == 1 ? true : false,
            is_open_moon_enable: data.is_open_moon_enable == 1 ? true : false,
            is_open_gst:data.is_open_gst == 1 ? true:false,
            is_discount: data.is_discount == 1 ? true : false,
            order_expire: data.order_expire || 30,
            activity_order_expire: data.activity_order_expire || 30,
            is_open_spu_many_help: data.is_open_spu_many_help == 1 ? true : false,
            many_help_order_expire: data.many_help_order_expire || 1,
            unpay_wait_time:data.unpay_wait_time,

            stop_order_time: data.stop_order_time || 15,
            receiving_order_time: data.receiving_order_time || 15,

            

        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let {is_order_receiving,unpay_wait_time, business_status, is_partner_gst,is_partner_moon_enable, is_allday_order,is_open_recommend, is_holiday_business, is_open_share } = this.state
        let data = {}
        data.business_status = business_status ? 1 : "0"
        data.is_allday_order = is_allday_order ? 1 : "0"
        data.is_partner_moon_enable = is_partner_moon_enable ? 1 : "0"
        data.is_partner_gst = is_partner_gst ? 1 : "0"
        data.is_open_recommend = is_open_recommend ? 1 : "0"
        // data.is_user_address = is_user_address ? 1 : "0"
        // data.is_force_user_address = is_force_user_address ? 1 : "0"
        data.is_holiday_business = is_holiday_business ? 1 : "0"
        data.is_open_share = is_open_share ? 1 : "0"
        data.order_expire = this.props.form.getFieldValue('order_expire')
        data.activity_order_expire = this.props.form.getFieldValue('activity_order_expire')
        data.many_help_order_expire = this.props.form.getFieldValue('many_help_order_expire')
        data.unpay_wait_time = this.props.form.getFieldValue('unpay_wait_time')

        data.is_order_receiving = is_order_receiving ? 1 : "0"
        data.receiving_order_time = this.props.form.getFieldValue('receiving_order_time')
        data.stop_order_time = this.props.form.getFieldValue('stop_order_time')


        UpdateShopMessage(data)
    };

    check_order_expire(rule, value, callback) {
        if (value > 180 || value < 15) {
            callback(new Error(intl.get("setup.form.order_expire_tip")));
        } else {
            callback();
        }
    }

    activity_order_expire(rule, value, callback) {
        if (value > 180 || value < 15) {
            callback(new Error(intl.get("setup.form.order_expire_tip")));
        } else {
            callback();
        }
    }

    many_help_order_expire(rule, value, callback) {
        if (value > 90 || value < 1) {
            callback(new Error(intl.get("setup.form.activity_order_expire_tip")));
        } else {
            callback();
        }
    }

    render() {
        const {  is_order_receiving, stop_order_time, receiving_order_time,  unpay_wait_time,TimeData, is_open_gst, is_open_moon_enable,is_audit,min_pay_fee_on_online,is_allday_order,is_partner_moon_enable, is_partner_gst,business_status, is_open_recommend, is_authorization_code, is_discount, is_holiday_business, is_open_share, order_expire, activity_order_expire, is_open_spu_many_help, many_help_order_expire } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ width: "1000px", marginLeft: "100px" }}>
                {/* <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get("behavior.Order.amount.setting")}</div>
                    <Form.Item
                        label={intl.get("behavior.Online.payment.order")}
                    >
                        {getFieldDecorator('min_pay_fee_on_online', {
                            rules: [{ required: false }],
                            initialValue: min_pay_fee_on_online
                        })(<Input prefix={currency_symbol} disabled />)}
                    </Form.Item>
                </Card> */}
                {/* <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>第三方平台支付</div>
                    <Form.Item
                        label="选择支付渠道"
                    >
                        {getFieldDecorator('payment_type', {
                            rules: [{ required: false }],
                            initialValue: data.payment_type
                        })(
                            <Radio.Group>
                                <Radio value={"Royal Pay"}>Royal Pay</Radio>
                                <Radio value={"Stripe、Royal Pay"}>Stripe、Royal Pay</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                </Card>
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>付款方式</div>
                    <Form.Item
                        label="选择支付方式"
                    >
                        {getFieldDecorator('payment_method', {
                            rules: [{ required: false }],
                            initialValue: data.payment_method
                        })(<Radio.Group>
                            <Radio disabled value={"0"}>不限</Radio>
                            <Radio value={"1"}>线上付款</Radio>
                            <Radio disabled value={"2"}>现金付款</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                </Card>
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>打包费</div>
                    <Form.Item
                        label="打包费"
                    >
                        <Switch disabled checked={is_charge_packing_fee} onChange={() => {
                            this.setState({
                                is_charge_packing_fee: !is_charge_packing_fee
                            })
                        }} />
                    </Form.Item>
                </Card> */}
                

                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('setup.form.isclose')}</div>
                    <Form.Item label={<span>{intl.get('setup.form.isclose')}</span>}>
                        <Switch checked={business_status} onChange={() => {
                            this.setState({
                                business_status: !business_status
                            })
                        }} />
                    </Form.Item>
                </Card>



                {/*
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>是否24小时下订单</div>
                    <Form.Item label={<span>是否24小时下订单</span>}>
                        <Switch checked={is_allday_order} onChange={() => {
                            this.setState({
                                is_allday_order: !is_allday_order
                            })
                        }} />
                    </Form.Item>
                </Card>
                */}


                {is_audit &&  is_open_moon_enable && 
                    <Card style={{ marginBottom: "10px" }}>
                        <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>开启小月亮</div>
                        <Form.Item label={<span>开启小月亮</span>}>
                            <Switch checked={is_partner_moon_enable} onChange={() => {
                                this.setState({
                                    is_partner_moon_enable: !is_partner_moon_enable
                                })
                            }} />
                        </Form.Item>
                    </Card>
                }



                { 
                    <Card style={{ marginBottom: "10px" }}>
                        <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>未付款订单取消时间</div>
                           <Form.Item
                            label={<span>未付款订单取消时间</span>}
                        >
                            {getFieldDecorator('unpay_wait_time', {
                                rules: [{ required: false}],
                                initialValue:  unpay_wait_time
                            })(
                                <Select
                                    onChange={(e) => {
                                        //this.handleGetPackageData(e)
                                    }}
                                >
                                    {TimeData && TimeData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>

                    </Card>
                }





                

                 {is_audit && is_open_gst && 
                    <Card style={{ marginBottom: "10px" }}>
                        <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>GST</div>
                        <Form.Item label={<span>有商品 GST</span>}>
                            <Switch checked={is_partner_gst} onChange={() => {
                                this.setState({
                                    is_partner_gst: !is_partner_gst
                                })
                            }} />
                        </Form.Item>
                    </Card>
                }










                {/* <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('nav.event.openSelected')}</div>
                    <Form.Item label={<span>{intl.get('nav.event.openSelected')}</span>}>
                        <Switch
                            checked={is_open_recommend}
                            onChange={() => {
                                this.setState({
                                    is_open_recommend: !is_open_recommend
                                })
                            }} />
                    </Form.Item>
                </Card> */}

                {/* <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('nav.goodsManagement.OpenHoliday')}</div>
                    <Form.Item label={<span>{intl.get('nav.goodsManagement.OpenHoliday')}</span>}>
                        <Switch
                            checked={is_holiday_business}
                            onChange={() => {
                                this.setState({
                                    is_holiday_business: !is_holiday_business
                                })
                            }} />
                    </Form.Item>
                </Card> */}

                {/*<Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('user.table.share')}</div>
                    <Form.Item label={<span>{intl.get('user.table.share')}</span>}>
                        <Switch
                            checked={is_open_share}
                            onChange={() => {
                                this.setState({
                                    is_open_share: !is_open_share
                                })
                            }} />
                    </Form.Item>
                </Card>*/}








                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get("home.table.way.name_d")}</div>
                     
                    <Form.Item label={<span>{intl.get('nav.goodsManagement.OpenOrderReceiving')}</span>}>
                        <Switch
                            checked={is_order_receiving}
                            onChange={() => {
                                this.setState({
                                    is_order_receiving: !is_order_receiving
                                })
                            }} />
                    </Form.Item>


                    {
                        is_order_receiving &&
                        <Form.Item label={<span>{intl.get('setup.form.stop_order_time')}</span>}>
                            {getFieldDecorator('stop_order_time', {
                                rules: [{ required: false, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }],
                                initialValue: stop_order_time
                            })(<Input suffix="mins" style={{ width: "100px" }} />)}
                        </Form.Item>

                    }



                    {/*
                        is_order_receiving &&
                        <Form.Item label={<span>等待接单时间</span>}>
                            {getFieldDecorator('receiving_order_time', {
                                rules: [ ,{ required: false, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }, { validator: (rule, value, callback) => { this.check_time(rule, value, callback) } } ],
                                initialValue: receiving_order_time
                            })(<Input suffix="mins" style={{ width: "100px" }} />)}
                        </Form.Item>
                    */
                    }



                     <Form.Item
                        label={<span>等待接单时间</span>}
                    >
                        {getFieldDecorator('receiving_order_time', {
                            rules: [{ required: false}],
                            initialValue: receiving_order_time
                        })(
                            <Select
                                onChange={(e) => {
                                    //this.handleGetPackageData(e)
                                }}
                            >
                                {TimeData && TimeData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>






                    
                    
                </Card>





                {/*
                    is_authorization_code &&
                    <Card style={{ marginBottom: "10px" }}>
                        <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('setup.form.order_expire_header')}</div>
                        <Form.Item label={<span>{intl.get('setup.form.order_expire')}</span>}>
                            {getFieldDecorator('order_expire', {
                                rules: [
                                    { required: false },
                                    { validator: (rule, value, callback) => { this.check_order_expire(rule, value, callback) } }
                                ],
                                getValueFromEvent: (event) => {
                                    return event.target.value.replace(/[^\d]/g, '')
                                },
                                initialValue: order_expire
                            })(<Input suffix="days" style={{ width: "100px" }} />)}
                        </Form.Item>
                        {
                            is_discount &&
                            <Form.Item label={<span>{intl.get('setup.form.activity_order_expire')}</span>}>
                                {getFieldDecorator('activity_order_expire', {
                                    rules: [
                                        { required: false },
                                        { validator: (rule, value, callback) => { this.activity_order_expire(rule, value, callback) } }
                                    ],
                                    getValueFromEvent: (event) => {
                                        return event.target.value.replace(/[^\d]/g, '')
                                    },
                                    initialValue: activity_order_expire
                                })(<Input suffix="days" style={{ width: "100px" }} />)}
                            </Form.Item>
                        }

                        {is_open_spu_many_help &&
                            <Form.Item label={<span>{intl.get('setup.form.many_help_order_expire')}</span>}>
                                {getFieldDecorator('many_help_order_expire', {
                                    rules: [
                                        { required: false },
                                        { validator: (rule, value, callback) => { this.many_help_order_expire(rule, value, callback) } }
                                    ],
                                    getValueFromEvent: (event) => {
                                        return event.target.value.replace(/[^\d]/g, '')
                                    },
                                    initialValue: many_help_order_expire
                                })(<Input suffix="days" style={{ width: "100px" }} />)}
                            </Form.Item>
                        }
                    </Card>*/
                }




                {/* <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('nav.goodsManagement.OpenUserAddress')}</div>
                    <Form.Item label={<span>{intl.get('nav.goodsManagement.OpenUserAddress')}</span>}>
                        <Switch
                            checked={is_user_address}
                            onChange={() => {
                                if (is_user_address) {
                                    this.setState({
                                        is_force_user_address: false
                                    })
                                }
                                this.setState({
                                    is_user_address: !is_user_address
                                })

                            }} />
                    </Form.Item>
                    <Form.Item label={
                        <span>
                            {intl.get('nav.goodsManagement.Forced_open')}
                            <Tooltip title={intl.get("nav.goodsManagement.Forced_open_tips")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>

                    }>
                        <Switch
                            checked={is_force_user_address}
                            onChange={() => {
                                if (is_user_address) {
                                    this.setState({
                                        is_force_user_address: !is_force_user_address
                                    })
                                }

                            }} />
                    </Form.Item>
                </Card> */}


                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form >

        );
    }
}

export default withRouter(Form.create()(Behavior))
